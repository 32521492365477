<template>
  <div>
    <van-tag
      v-if="!isSubscribe"
      round
      size="large"
      type="primary"
      @click="addSub"
      >订阅</van-tag
    >
    <van-tag
      v-if="isSubscribe && showDelete"
      round
      size="large"
      type="primary"
      @click="deleteSub"
      >取消订阅</van-tag
    >
  </div>
</template>
<script>
import { IsSubscribe, AddSubscribe, DeleteSubscribe } from "@/api/subscribe";
import { GetWXUserStatus } from "@/api/wxuser";
import { Toast, Dialog } from "vant";
export default {
  props: {
    subpara: {
      type: Object,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isSubscribe: false,
      wxuserStatus: 0,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let subresult = await IsSubscribe(this.subpara);
      if (subresult.data.data) {
        this.isSubscribe = true;
      } else {
        let wxuserStatusResult = await GetWXUserStatus();
        if (wxuserStatusResult.data.success) {
          this.wxuserStatus = wxuserStatusResult.data.data;
        }
      }
    },
    async addSub() {
      if (!this.isWenXin() && this.wxuserStatus != 2) {
        Dialog.alert({
          title: "提示",
          message:
            "订阅消息将通过中酿IT服务公众号发送,请在微信中打开， 去‘绑定微信’ 查看绑定信息，关注中酿IT服务公众号",
        });
        return;
      }

      if (this.wxuserStatus == 0) {
        //请在微信中打开， 并在我的 绑定微信 查看绑定信息，并关注中酿IT服务公众号
        Dialog.confirm({
          title: "提示",
          confirmButtonText: "去绑定",
          cancelButtonText: "取消",
          message:
            "订阅消息将通过中酿IT服务公众号发送，去‘绑定微信’ 绑定信息账号，关注中酿IT服务公众号,",
        })
          .then(() => {
            this.$router.push({
              path: "/user/bindwxpre",
            });
          })
          .catch(() => {});
      }
      if (this.wxuserStatus == 1) {
        //请在 我的》绑定微信 关注中酿IT服务公众号，方便接受订阅消息
        //请在微信中打开， 并在我的 绑定微信 查看绑定信息，并关注中酿IT服务公众号
        Dialog.confirm({
          title: "提示",
          confirmButtonText: "去关注",
          cancelButtonText: "取消",
          message:
            "订阅消息将通过中酿IT服务公众号发送，订阅前，去‘绑定微信’ 查看关注中酿IT服务公众号",
        })
          .then(() => {
            this.$router.push({
              path: "/user/bindwxpre",
            });
          })
          .catch(() => {});
      }
      if (this.wxuserStatus == 2) {
        let subresult = await AddSubscribe(this.subpara);
        if (subresult.data.success) {
          this.isSubscribe = true;
          Toast("订阅成功");
        }
      }
    },
    isWenXin: function () {
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") !== -1) {
        return true; // 是微信端
      } else {
        return false;
      }
    },

    async deleteSub() {
      let subresult = await DeleteSubscribe(this.subpara);
      if (subresult.data.success) {
        this.isSubscribe = false;
        Toast("取消订阅");
      }
    },
  },
};
</script>