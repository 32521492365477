<template>
  <div class="container"  ref="wrapperarticle">
    <van-search
      v-if="subTypeOption.length <= 1"
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <div v-if="!fromproduct">
      <van-row v-if="subTypeOption.length > 1">
        <van-col span="8">
          <van-dropdown-menu>
            <van-dropdown-item
              v-model="page.filterData.subTypeIds"
              :options="subTypeOption"
              @change="onSearch"
            />
          </van-dropdown-menu>
        </van-col>
        <van-col span="16">
          <van-search
            v-model="page.filterData.title"
            @search="onSearch"
            placeholder="请输入搜索关键词"
          />
        </van-col>
      </van-row>

      <van-cell>
        <van-col span="4">
          <van-tag
            round
            size="large"
            :color="page.sorterType == 0 ? '#9d1d22' : ''"
            @click="changeSorterType(0)"
            >默认</van-tag
          >
        </van-col>
        <van-col span="4">
          <van-tag
            round
            size="large"
            :color="page.sorterType == 1 ? '#9d1d22' : ''"
            @click="changeSorterType(1)"
            >最新</van-tag
          >
        </van-col>
        <van-col span="4">
          <van-tag
            round
            size="large"
            :color="page.sorterType == 3 ? '#9d1d22' : ''"
            @click="changeSorterType(3)"
            >最热</van-tag
          >
        </van-col>
        <van-col span="4">
          <van-tag
            round
            size="large"
            :color="page.sorterType == 2 ? '#9d1d22' : ''"
            @click="changeSorterType(2)"
            >最早</van-tag
          >
        </van-col>
        <van-col span="8" v-if="type==102">
          <Subscribe :subpara="subpara"></Subscribe>
        </van-col>
      </van-cell>
      <van-cell
        v-if="
          page.filterData.productId != '00000000-0000-0000-0000-000000000000'
        "
      >
        <van-col span="24">
          <van-tag
            v-if="
              page.filterData.productId !=
              '00000000-0000-0000-0000-000000000000'
            "
            closeable
            round
            size="large"
            color="#9d1d22"
            @close="closeproduct"
          >
            {{ productName }}
          </van-tag>
        </van-col>
      </van-cell>
    </div>
    <van-button v-if="type==102" type="info" round block @click="toPackage()">经验包</van-button>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell
            @click="toArticleDetail(item.id.toString())"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <van-col span="6"
              ><van-image
                lazy-load
                class="w100"
                fit="contain"
                style="max-height: 200px"
                :src="item.imageUrl"
              />
            </van-col>
            <van-col span="18" align="bottom">
              <div
                style="display: table-cell; vertical-align: middle"
                class="zntitle van-multi-ellipsis--l3"
              >
                <h3 style="display: inline-block">{{ item.title }}</h3>
              </div>
              <div
                style="float: left"
                v-if="
                  item.productName != undefined && item.productName.length > 0
                "
              >
                <van-tag  v-if="!fromproduct"
                  @click.stop="toProduct(item)"
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >{{ item.productName }}
                </van-tag>
                 <van-tag  v-if="fromproduct"
                  size="large"
                  color="rgb(255 242 219)"
                  text-color="#9d1d22"
                  >{{ item.productName }}
                </van-tag>
              </div>
              <div style="float: right">{{ item.creationTime }}</div>
              <div style="float: right; margin-right: 13px">
                浏览{{ item.readCount }}人次
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getArticleList, getArticleTypeList } from "@/api/article";
import { getArticleType } from "@/utils/datetype";
import Subscribe from "../components/subscribe";

//const key = "zn-history";
export default {
  name: "znarticle",
  props: {
    type: Number,
    productId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,
      productName: "",
      subTypeOption: [],
      title: "",
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      fromproduct: false,
      subpara:{
  itemId: "",
  itemType: 102,
  typeName: "进阶S7攻略"

      },


      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          subTypeIds: "",
          title: "",
          typeId: 0,
          productId: "00000000-0000-0000-0000-000000000000",
        },
      },
    };
  },
  components: {
     Subscribe,
  },

   activated() {
    try {
      this.$refs.wrapperarticle.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },

  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    toPackage(){
      this.$router.push({
            path: "/course/coursePackets",
            query: { typeid: 401,},
          });
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
      // let type = this.$route.query.type;
      this.page.filterData.typeId = this.type;
      if (this.productId != undefined && this.productId != "") {
        this.page.filterData.productId = this.productId;
        this.fromproduct = true;
      }
      this.title = getArticleType(this.type);

      let subTypeList = await getArticleTypeList({
        current: 1,
        pageSize: 100,
        filterData: {
          parentID: this.type,
        },
      });
      this.subTypeOption = [{ text: "全部", value: "" }];
      subTypeList.data.data.map((x) =>
        this.subTypeOption.push({ text: x.typeName, value: x.id.toString() })
      );
    },
    toProduct(item) {
      if (this.page.filterData.productId != item.productId) {
        this.productName = item.productName;
        this.page.filterData.productId = item.productId;
        this.onSearch();
      }
    },
    closeproduct() {
      this.page.filterData.productId = "00000000-0000-0000-0000-000000000000";
      this.onSearch();
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toArticleDetail(id) {
      try {
        let wrapperScrollTop = this.$refs.wrapperarticle.scrollTop;
        this.scroll = wrapperScrollTop;
      } catch (error) {
        console.log(error);
      }
      this.$router.push({
        path: "/article/detail",
        query: { para: id, type: this.page.filterData.typeId },
      });
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getArticleList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.sorterType != typeid) {
        this.page.sorterType = typeid;
        this.onSearch();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-list__loading {
    height: calc(80vh);
  }
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
